import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src3499616894/src/kinetic-ui/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "categories"
    }}>{`Categories`}</h1>
    <hr />
    <h2 {...{
      "id": "fetchcategories"
    }}>{`fetchCategories`}</h2>
    <h3 {...{
      "id": "params"
    }}>{`Params`}</h3>
    <pre><code parentName="pre" {...{}}>{`* kappSlug -> string = (required) slug of the kapp the categories are in
`}</code></pre>
    <h3 {...{
      "id": "resolves"
    }}>{`Resolves`}</h3>
    <pre><code parentName="pre" {...{}}>{`* categories -> Category[] = an array of categories
* error -> ErrorResponse{} = an error object on failure
`}</code></pre>
    <h3 {...{
      "id": "example"
    }}>{`Example`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { fetchCategories } from '@kineticdata/react';

fetchCategories({ kappSlug }).then(({ categories }) => console.log(categories));
`}</code></pre>
    <br />
    <hr />
    <h2 {...{
      "id": "fetchcategory"
    }}>{`fetchCategory`}</h2>
    <h3 {...{
      "id": "params-1"
    }}>{`Params`}</h3>
    <pre><code parentName="pre" {...{}}>{`* categorySlug -> string = (required) slug of the category
* kappSlug -> string = (required) slug of the kapp the category is in
`}</code></pre>
    <h3 {...{
      "id": "resolves-1"
    }}>{`Resolves`}</h3>
    <pre><code parentName="pre" {...{}}>{`* category -> Category{} = a category object
* error -> ErrorResponse{} = an error object on failure
`}</code></pre>
    <h3 {...{
      "id": "example-1"
    }}>{`Example`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { fetchCategory } from '@kineticdata/react';

fetchCategory({ kappSlug, categorySlug }).then(({ category }) =>
  console.log(category),
);
`}</code></pre>
    <br />
    <hr />
    <h2 {...{
      "id": "updatecategory"
    }}>{`updateCategory`}</h2>
    <h3 {...{
      "id": "params-2"
    }}>{`Params`}</h3>
    <pre><code parentName="pre" {...{}}>{`* categorySlug -> string = (required) slug of the category
* category -> Category{} = (required) the updated category data to send
* kappSlug -> string = (required) slug of the kapp the category is in
`}</code></pre>
    <h3 {...{
      "id": "resolves-2"
    }}>{`Resolves`}</h3>
    <pre><code parentName="pre" {...{}}>{`* category -> Category{} = the updated category object
* error -> ErrorResponse{} = an error object on failure
`}</code></pre>
    <h3 {...{
      "id": "example-2"
    }}>{`Example`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { updateCategory } from '@kineticdata/react';

updateCategory({
  kappSlug,
  categorySlug,
  category: categoryWithChanges,
}).then(({ category }) => console.log(category));
`}</code></pre>
    <br />
    <hr />
    <h2 {...{
      "id": "createcategory"
    }}>{`createCategory`}</h2>
    <h3 {...{
      "id": "params-3"
    }}>{`Params`}</h3>
    <pre><code parentName="pre" {...{}}>{`* category -> Category{} = (required) the category data to send
* kappSlug -> string = (required) slug of the kapp the category is in
`}</code></pre>
    <h3 {...{
      "id": "resolves-3"
    }}>{`Resolves`}</h3>
    <pre><code parentName="pre" {...{}}>{`* category -> Category{} = the created category object
* error -> ErrorResponse{} = an error object on failure
`}</code></pre>
    <h3 {...{
      "id": "example-3"
    }}>{`Example`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { createCategory } from '@kineticdata/react';

createCategory({ kappSlug, category }).then(({ category }) =>
  console.log(category),
);
`}</code></pre>
    <br />
    <hr />
    <h2 {...{
      "id": "deletecategory"
    }}>{`deleteCategory`}</h2>
    <h3 {...{
      "id": "params-4"
    }}>{`Params`}</h3>
    <pre><code parentName="pre" {...{}}>{`* categorySlug -> string = (required) slug of the category
* kappSlug -> string = (required) slug of the kapp the category is in
`}</code></pre>
    <h3 {...{
      "id": "resolves-4"
    }}>{`Resolves`}</h3>
    <pre><code parentName="pre" {...{}}>{`* category -> Category{} = the deleted category object
* error -> ErrorResponse{} = an error object on failure
`}</code></pre>
    <h3 {...{
      "id": "example-4"
    }}>{`Example`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { deleteCategory } from '@kineticdata/react';

deleteCategory({ kappSlug, categorySlug }).then(({ category }) =>
  console.log(category),
);
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      